import newsData from "./../../db/newsData.json";
import NewsPost from "./newsPost/NewsPost";
import NewsHeader from "./newsHeader/NewsHeader";
// import ReadMore from "../../ui/readMore/ReadMore";

import "./newsSection.scss";

interface NewsItem {
  url: string;
  img: string;
  title: string;
  bgColor?: string;
  objectPos?: string;
}

const TypedNewsData = newsData as NewsItem[];

const NewsSection: React.FC = () => {
  return (
    <section className="news-section" id="insights">
      <NewsHeader />
      <div
        className="news-section__news"
        aria-label="In Sync news - our studio insights"
      >
        {TypedNewsData.map((item, index) => (
          <NewsPost
            key={index}
            url={item.url}
            img={item.img}
            title={item.title}
            bgColor={item.bgColor}
            objectPos={item.objectPos}
          />
        ))}
      </div>
      {/*<div className='news-section__cta'>*/}
      {/*    <ReadMore variant='light' url='#!' text='Read all news'/>*/}
      {/*</div>*/}
    </section>
  );
};

export default NewsSection;
