import { useRef, useState } from "react";
import "./testimonials.scss";

import TestimonialLinks from "../../ui/testimonialLinks/TestimonialLinks";
import testimonialsData from "../../db/testimonialsData.json";

const Testimonials: React.FC = () => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (gridRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - gridRef.current.offsetLeft);
      setScrollLeft(gridRef.current.scrollLeft);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !gridRef.current) return;

    e.preventDefault();
    const x = e.pageX - gridRef.current.offsetLeft;
    const walk = x - startX;
    gridRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  return (
    <section className="testimonials" id="testimonials">
      <div className="testimonials__header">
        <p>Client stories</p>
        <nav className="testimonials__nav">
          <button
            onClick={() =>
              gridRef.current?.scrollBy({ left: -300, behavior: "smooth" })
            }
          >
            ←
          </button>
          <button
            onClick={() =>
              gridRef.current?.scrollBy({ left: 300, behavior: "smooth" })
            }
          >
            →
          </button>
        </nav>
      </div>

      <div className="testimonials__carousel">
        <div
          className="testimonials__grid"
          ref={gridRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: isDragging ? "grabbing" : "grab",
            userSelect: isDragging ? "none" : "text",
          }}
        >
          {testimonialsData.map((testimonial, index) => (
            <article key={index} className="testimonials__slide">
              {testimonial.video && (
                <video
                  src={testimonial.video}
                  poster={testimonial.cover}
                  playsInline={true}
                  controls={true}
                  className="testimonials__cover"
                  draggable={false}
                />
              )}
              {testimonial.testimonial && (
                <p className="testimonial">{testimonial.testimonial}</p>
              )}
              <div className="testimonials__client">
                <div className="testimonials__client-info">
                  <p className="testimonials__client-name">
                    {testimonial.clientName}
                  </p>
                  <p className="testimonials__client-title">
                    {testimonial.clientTitle}
                  </p>
                </div>
                <div className="testimonials__client-company">
                  <img
                    src={testimonial.companyLogo}
                    alt="company logo"
                    draggable={false}
                  />
                  <p className="testimonials__client-company">
                    {testimonial.clientCompany}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>

      <div className="testimonials__clients-links">
        <p>
          Explore more what our clients are saying on
          <TestimonialLinks
            url={
              "https://clutch.co/profile/sync-0?_gl=1*18vde21*_ga*ODAzMTg4NDQ3LjE2NTY1MDA5OTA.*_ga_D0WFGX8X3V*MTY1ODc0ODA0NS4xNC4xLjE2NTg3NDgwODYuMTk.#highlights"
            }
            text={"Clutch"}
          />{" "}
          and
          <TestimonialLinks
            url={"https://www.upwork.com/agencies/1380084536310538240/"}
            text={"Upwork"}
          />
        </p>
      </div>
    </section>
  );
};

export default Testimonials;
