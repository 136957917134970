import { useEffect } from "react";
import "./mainScreenMobile.scss";
import sync from "../../resources/video/in-sync-main.mov";

import ReadMore from "../../ui/readMore/ReadMore";

interface MainScreenMobileProps {
  mainRef: React.RefObject<HTMLDivElement>;
  setScrollable: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainScreenMobile: React.FC<MainScreenMobileProps> = ({
  mainRef,
  setScrollable,
}) => {
  useEffect(() => {
    setTimeout(() => {
      setScrollable(true);
    }, 3500);
  }, [mainRef, setScrollable]);
  return (
    <section className="main-screen__mobile-container">
      <div className='main-screen__mobile-info'>
        <div className="main-screen__mobile-help-how">
          <p>
            Helping digital products scale with strategic, research-driven UX/UI
          </p>
        </div>
        <div className="main-screen__mobile-help-talk">
          <ReadMore
            variant="dark"
            target="_blank"
            url="https://calendly.com/mariia-myroshnichenko/design-intro-meeting"
            text="Let's talk today"
          />
        </div>
      </div>
      <video
        className="main-screen__video-mobile"
        autoPlay
        muted
        loop
        playsInline
        src={sync}
      />
    </section>
  );
};

export default MainScreenMobile;
