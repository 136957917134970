import "./mobileBurger.scss";

interface BurgerProps {
  active: boolean;
  setActive: (active: boolean) => void;
}

const MobileBurger: React.FC<BurgerProps> = ({ active, setActive }) => {
  const handleClose = () => setActive(false);

  const navItems = [
    { href: "#works", label: "Works," },
    { href: "#agency", label: "Agency," },
    { href: "#services", label: "Services," },
    { href: "#insights", label: "Insights" },
  ];

  return (
    <>
      <section className={`burger ${active ? "burger--visible" : ""}`}>
        <section className="burger-header">
          <div className="burger__logo-text">In Sync agency.</div>
          <button
            className="burger-close"
            aria-label="Close menu"
            onClick={handleClose}
          >
            <span aria-hidden="true" className="burger-close_cross">
              ╳
            </span>
          </button>
        </section>
        <section className="burger-main">
          <nav className="burger-info">
            <ul className="burger__nav-list">
              {navItems.map((item) => (
                <li key={item.href}>
                  <a
                    href={item.href}
                    className="burger__nav-link"
                    onClick={handleClose}
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <button className="burger__cta-container" onClick={handleClose}>
            <a href="#footer" className="burger__cta">
              Contact us
            </a>
          </button>
          <footer className="burger__copyright">
            <p>Driven by craft, culture and imagination</p>
            <p>©2019––2024, All rights reserved.</p>
          </footer>
        </section>
      </section>
    </>
  );
};

export default MobileBurger;
