import { useState, useEffect } from "react";
import "./appFooter.scss";
import FooterLeftBlock from "./footerLeftBlock/FooterLeftBlock";
import FooterRightBlock from "./footeRightBlock/FooterRightBlock";
import FooterBanner from "./footerBanner/FooterBanner";
import FooterMobile from "./footerMobile/FooterMobile";

const AppFooter: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 480);
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <footer className="app-footer" id="footer">
      {isMobile ? (
        <FooterMobile />
      ) : (
        <>
          <div className="footer__banner">
            <FooterBanner />
          </div>

          <div className="footer__content">
            <div className="footer__left">
              <FooterLeftBlock />
            </div>
            <div className="footer__right">
              <FooterRightBlock />
            </div>
          </div>
        </>
      )}
    </footer>
  );
};

export default AppFooter;
