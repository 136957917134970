import "./ourTeam.scss";

import zoom from "../../resources/video/zoom-meeting.mp4";

const OurTeam: React.FC = () => {
  return (
    <section className="our-team" id="agency">
      <div className="our-team__background">
        <video
          src={zoom}
          autoPlay
          loop
          muted
          playsInline
          aria-label="Background video of our team meeting"
        />
      </div>

      <hr className="our-team__separator" />

      <div className="our-team__title">
        <p>
          We are a small team of creative thinkers specializing in crafting
          tailored digital experiences that resonate with your audience
        </p>
      </div>

      <div className="our-team__inspiration-words">
        <p>
          Enrichment – Interests – Collective aspirations – Experience – Desires
          – Interaction
        </p>
        <p>
          Aspirations – Future – Objectives – Vision – Creativity – Evolution –
          Missions – Humanity
        </p>
        <p>
          Inner aspirations – Personal growth – Solutions – Society –
          Inspiration – Communication
        </p>
      </div>
    </section>
  );
};

export default OurTeam;
