import "./newsPost.scss";

interface NewsPostProps {
  url: string;
  img: string;
  title: string;
  bgColor?: string;
  objectPos?: string;
}

const NewsPost: React.FC<NewsPostProps> = ({
  url,
  img,
  title,
  bgColor = "none",
  objectPos,
}) => {
  return (
    <article className="news-post">
      <a
        href={url}
        className="news-post__link"
        aria-label={`Read more about ${title}`}
      >
        <figure className="news-post__figure">
          <div
            style={{ backgroundColor: bgColor }}
            className="news-post__image-wrapper"
          >
            <img
              src={img}
              alt={title}
              className="news-post__image"
              loading="lazy"
              style={objectPos ? { objectPosition: objectPos } : {}}
            />
          </div>
          <figcaption className="news-post__title">{title}</figcaption>
        </figure>
      </a>
    </article>
  );
};

export default NewsPost;
