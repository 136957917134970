import { useRef, useState } from "react";
import ContactUs from "../../ui/contactUs/ContactUs";
import NavLink from "../../ui/navLink/NavLink";
import MobileBurger from "../mobileBurger/MobileBurger";

import "./appHeader.scss";

interface IProps_AppHeader {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isVisible: boolean;
}

const AppHeader: React.FC<IProps_AppHeader> = ({ isVisible }) => {
  const [burgerActive, setBurgerActive] = useState(false);

  const navigationRef = useRef<HTMLUListElement>(null);

  return (
    <>
      <header className={`app-header ${isVisible ? "visible" : "hidden"}`}>
        <div className="header__left">
          <p>In Sync agency.</p>
        </div>

        <div className="header__right">
          <nav className="nav" ref={navigationRef}>
            <ul className="nav__list">
              <span className="nav__logo" />
              <li className="nav__item">
                <NavLink variant="light" url="#works" text="Works, " />
              </li>
              <li className="nav__item">
                <NavLink variant="light" url="#agency" text="Agency, " />
              </li>
              <li className="nav__item">
                <NavLink variant="light" url="#services" text="Services, " />
              </li>
              <li className="nav__item">
                <NavLink variant="light" url="#insights" text="Insights" />
              </li>
            </ul>
          </nav>

          <div className="contact">
            <ContactUs />
          </div>

          <button className="menu-button">
            <span className="menu-button__bullet">&bull;</span>
            <p
              className="menu-button__text"
              onClick={() => setBurgerActive(true)}
            >
              Menu
            </p>
          </button>
        </div>
      </header>

      <MobileBurger active={burgerActive} setActive={setBurgerActive} />
    </>
  );
};

export default AppHeader;
