import "./testimonialLinks.scss";

interface TestimonialLinksProps {
    url: string;
    text: string;
}

const TestimonialLinks: React.FC<TestimonialLinksProps> = ({url, text}) => {
  return (
      <a
        href={url}
        target="_blank"
        className="testimonial-links"
      >
        {text}<span>→</span>
      </a>
  );
};

export default TestimonialLinks;
