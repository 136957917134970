import AppFooter from "../appFooter/AppFooter";
import AboutUs from "../aboutUs/AboutUs";
import FeaturedWorks from "../featuredWorks/FeaturedWorks";
import AppHeader from "../appHeader/AppHeader";
import SplashScreen from "../splashScreen/SplashScreen";
import OurTeam from "../ourTeam/OurTeam";
import NewsSection from "../news/NewsSection";
import DiverseFields from "../diverseFields/DiverseFields";
import Testimonials from "../testimonials/Testimonials";
import OurCapabilities from "../ourCapabilities/OurCapabilities";
import MainScreen from "../mainScreen/MainScreen";
import MainScreenMobile from "../mainScreenMobile/MainScreenMobile";

import { useRef, useState, useEffect } from "react";

const App: React.FC = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 481);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);

  let lastScrollTop = useRef(0);

  const mediaQuery = window.matchMedia("(max-width: 481px)");

  useEffect(() => {
    const handleResize = (event: MediaQueryListEvent) => {
      setIsMobileView(event.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  useEffect(() => {
    let headerTimer;

    if (mediaQuery) {
      headerTimer = 3500;
    } else {
      headerTimer = 6300;
    }

    setTimeout(() => {
      setIsHeaderVisible(true);
    }, headerTimer);

    const handleScroll = () => {
      if (!mainRef.current) return;

      const scrollTop = mainRef.current.scrollTop;

      if (scrollTop > lastScrollTop.current) {
        // Scrolling down
        setIsHeaderVisible(false);
      } else {
        // Scrolling up
        setIsHeaderVisible(true);
      }

      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    };

    const mainEl = mainRef.current;

    if (mainEl) {
      mainEl.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (mainEl) {
        mainEl.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <main className={`main ${scrollable ? "scrollable" : ""}`} ref={mainRef}>
      {isMobileView ? (
        <MainScreenMobile mainRef={mainRef} setScrollable={setScrollable} />
      ) : (
        <MainScreen mainRef={mainRef} setScrollable={setScrollable} />
      )}
      <AppHeader isVisible={isHeaderVisible} />
      <SplashScreen />
      <AboutUs />
      <FeaturedWorks />
      <DiverseFields />
      <OurCapabilities keepOthersOpen={true} />
      <Testimonials />
      <OurTeam />
      <NewsSection />
      <AppFooter />
    </main>
  );
};

export default App;
