import Accordion from "./accordion/Accordion";
import "./ourCapabilities.scss";
import capabilitiesData from "../../db/capabilitiesData.json";

interface AccordionItem {
  id: string;
  label: string;
  renderContent: () => JSX.Element;
}

interface CapabilitiesProps {
  keepOthersOpen: boolean;
}

const OurCapabilities: React.FC<CapabilitiesProps> = ({ keepOthersOpen }) => {
  const formattedData: AccordionItem[] = capabilitiesData.map((item) => ({
    id: item.id,
    label: item.label,
    renderContent: () => <p>{item.content}</p>,
  }));

  return (
    <section className="our-capabilities" id="services">
      <li className="our-capabilities__header">
        <span>&#9632;</span>Our capabilities
      </li>
      <Accordion items={formattedData} keepOthersOpen={keepOthersOpen} />
    </section>
  );
};

export default OurCapabilities;
