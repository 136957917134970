import { useEffect, useRef, useState } from "react";
import "./titleBlur.scss";

const TitleBlur: React.FC = () => {
  const [isBlurred, setIsBlurred] = useState(true);
  const worksHeadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsBlurred(false);
        } else {
          setIsBlurred(true);
        }
      },
      {
        root: worksHeadingRef.current?.closest(".main"),
        threshold: 1,
      }
    );

    if (worksHeadingRef.current) {
      observer.observe(worksHeadingRef.current);
    }

    return () => {
      if (worksHeadingRef.current) {
        observer.unobserve(worksHeadingRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={worksHeadingRef}
      className={`featured-works__heading ${isBlurred ? "blurry" : ""}`}
    >
      <p>FEATURED WORKS</p>
    </div>
  );
};

export default TitleBlur;
