import ReadMore from "../../ui/readMore/ReadMore";

import "./aboutUs.scss";

const AboutUs: React.FC = () => {
  return (
    <section className="about-us" id="about-us">
      <hr className="about-us__separator" />

      <p className="about-us__description">
        We prioritize harmonious design, client relations, and global impact,
        exceeding expectations through meaningful projects.
      </p>

      <div className="about-us__process">
        <p className="about-us__process-description">
          We combine creativity and functionality to surpass expectations in our
          designs. Our collaborative process ensures visually captivating
          solutions that align with client goals.
        </p>
      </div>

      <div className="about-us__links-container">
        <p className="about-us__links-intro">
          <span className="about-us__bullet" />
          Take time to know us better
        </p>

        <nav className="about-us__links">
          <ReadMore variant="dark" url="#agency" text="About agency" />
          <ReadMore variant="dark" url="#services" text="Our approach" />
        </nav>
      </div>
    </section>
  );
};

export default AboutUs;
